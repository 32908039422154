import React from "react";
import CarouselComponent from "../Components/CarouselComponent";
import Header from "../Components/Header";
import { useDocumentTitle } from "../Util/hooks/useDocumentTitle";

function ContactUs(props) {
  useDocumentTitle("Contact Us");
  return (
    <div>
      <div className="header-bg">
        <div className="navbar-section-contactbg">
          <Header />
          <section className="banner-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <h1 className="header-txt" data-aos="fade-up">
                    Contact Us
                  </h1>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section style={{ padding: "30px 0", backgroundColor: "#99ca3c" }}>
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-4 text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "150px",
              }}
            >
              <h3 className="font_2">
                <span className="green-dark">Call us on:</span>
                <span>
                  {" "}
                  <a style={{ color: "#FFFFFF" }} href="tel:01384985974">
                    0138 498 5974
                  </a>
                </span>
              </h3>
            </div>
            <div
              className="col-md-8 text-center"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "160px",
              }}
            >
              <div
                style={{ width: "100%", maxWidth: "450px", textAlign: "left" }}
              >
                <h6 className="font_2" style={{ marginBottom: "10px" }}>
                  <span className="green-dark">For General Inquiries: </span>
                  <span>
                    <a
                      style={{ color: "#FFFFFF" }}
                      href="mailto:info@marigoldinsulation.uk"
                    >
                      info@marigoldinsulation.uk
                    </a>
                  </span>
                </h6>
                <h6 className="font_2">
                  <span className="green-dark">For Complaints: </span>
                  <span>
                    <a
                      style={{ color: "#FFFFFF" }}
                      href="mailto:complaints@marigoldinsulation.uk"
                    >
                      complaints@marigoldinsulation.uk
                    </a>
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5 justify-content-center mt-3">
              <div className="qa-faq">
                <h2 className="fdaont_2">
                  How efficient is
                  <br />{" "}
                  <span>
                    <span className="green-light">YOUR</span>{" "}
                  </span>
                  home?
                </h2>
                <div className="company_btn_wrapper">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="text-decoration-none learn_btn_company_stats"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSd4e33mW5w0Uk_ySZ3OKX0XxS_6eh_fuawc6_bVU6xe-JRgtQ/viewform?usp=sf_link"
                  >
                    CHECK ELIGIBILITY
                  </a>
                </div>
              </div>

              <div className="qabout-faq mt-3 mb-3">
                <div className="row">
                  <div className="col-md-3">
                    <div className="qaboutbtn">
                      <a href="/faqs" className="qabout-btn">
                        FAQs
                      </a>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <h4>
                      Got a question first?
                      <br />
                      Visit our FAQs page
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ padding: "30px 0", backgroundColor: "#99ca3c" }}>
        <div>
          <div className="row">
            <div className="col-md-6 px-2 text-center">
              <h3 style={{ fontSize: "16px" }} className="font_2">
                <span className="green-dark">
                  Birmingham Office: Unit 8, Vernon Road, Halesowen, B62 8HN
                </span>
              </h3>
            </div>
            <div className="col-md-6 px-2 text-center">
              <h3 style={{ fontSize: "16px" }} className="font_2">
                <span className="green-dark">
                  Luton Office: 52 Overstone Road, Luton, LU4 8QZ
                </span>
              </h3>
            </div>
          </div>
        </div>
      </section>
      <div className="row">
        <div className="col-md-6 text-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9721.872637149363!2d-2.0485307302246087!3d52.47065869999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487096fd912a74bd%3A0x62cb8c409a70efc5!2s8%20Vernon%20Rd%2C%20Halesowen%20B62%208HN%2C%20UK!5e0!3m2!1sen!2s!4v1663778898422!5m2!1sen!2s"
            width="100%"
            title="map"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="col-md-6 text-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2462.393443056469!2d-0.4654178!3d51.8902837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487648d9da0d7257%3A0xefd7b5525d5237d9!2s52%20Overstone%20Rd%2C%20Luton%20LU4%208QZ!5e0!3m2!1sen!2suk!4v1674123061418!5m2!1sen!2suk"
            width="100%"
            height="450"
            title="map2"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      {/* <CarouselComponent /> */}
    </div>
  );
}

export default ContactUs;
